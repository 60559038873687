import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/user'

const combinedReducer = combineReducers({
  userSlice, // used for login state and tokens etc
})

const rootReducer = (state, action) => {
  // this resets the entire store to its initial state on logout
  if (action.type === 'user/setIsLoggedOut') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export default store
